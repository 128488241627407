<template>
  <div class="main-menu">
    <Header ref="header" v-if="this.$route.name!=='XiaoChengXu'" />
    <router-view :key="$route.fullPath"></router-view>
  </div>
</template>

<script>
import Header from '@/components/Header/index.vue'
export default {
  name: 'MainMenu',
  components: {
    Header
  }
}
</script>

<style lang="scss" scoped>
.main-menu {
  height: 100%;
}
</style>
