import Vue from 'vue'
import App from './App.vue'
import './routeGuard'
import router from './router'
import store from './store'
import {
  Input,
  Radio,
  RadioGroup,
  Checkbox,
  CheckboxGroup,
  Dialog,
  Button,
  Select,
  Option,
  Form,
  FormItem,
  Carousel,
  CarouselItem,
  InputNumber,
  DatePicker,
  Pagination,
  Message,
  Popover,
  Loading,
  MessageBox,
  Table,
  TableColumn,
  Col
} from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/styles/index.scss' // global css
import '@/assets/font/iconfont.css'


Vue.use(Input)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Col)
Vue.use(Dialog)
Vue.use(Select)
Vue.use(Option)
Vue.use(Button)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(InputNumber)
Vue.use(DatePicker)
Vue.use(Pagination)
Vue.component(Message)
Vue.component(MessageBox.confirm)
Vue.use(Popover)
Vue.use(Loading.directive)
Vue.use(Table)
Vue.use(TableColumn)

store.dispatch('GetSetting')
store.dispatch('GetIfLogin')
// store.dispatch('GetphoneQRcodeImg')
store.dispatch('GetType')
Vue.prototype.$message = Message
Vue.prototype.$confirm = MessageBox.confirm
Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  store,
  router
}).$mount('#app')
