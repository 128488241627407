export function debounce(fn, delay = 300, trailing = true, immediate = true) {
  let timer
  if (trailing) {
    return function(...arg) {
      clearTimeout(timer)
      timer = setTimeout(() => {
        fn.apply(this, arg)
      }, delay)
    }
  } else {
    let canDo = immediate
    return function(...arg) {
      if (canDo) {
        canDo = false
        fn.apply(this, arg)
      } else {
        clearTimeout(timer)
      }
      timer = setTimeout(() => {
        canDo = true
      }, delay)
    }
  }
}