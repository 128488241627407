import request from '@/utils/request'

// 首页-banner
export function api_banner(data) {
  return request({
    url: `/banner/indexList?groupcode=${data.groupcode}`,
    method: 'get'
  })
}

// 首页-最近演出
export function api_projectList(data) {
  return request({
    url: `/project/projectList?groupcode=${data.groupcode}`,
    method: 'get'
  })
}

// 头部-分类
export function api_projectTypeList(data) {
  return request({
    url: `/project/projectTypeList?groupcode=${data.groupcode}`,
    method: 'get'
  })
}

// 首页-分类
export function api_projectTypeBrowseList(data) {
  return request({
    url: `/project/projectTypeBrowseList?groupcode=${data.groupcode}`,
    method: 'get'
  })
}

// 获取设置
export function api_appInfo() {
  return request({
    url: `/appInfo`,
    method: 'get'
  })
}