import request from '@/utils/request'

export function api_sendCode(data) {
  return request({
    url: `/sms/send`,
    method: 'post',
    data
  })
}

export function api_sendCaptcha() {
  return request({
    url: `/captcha`,
    method: 'get',
    responseType: 'blob'
  })
}

export function api_login(data) {
  return request({
    url: `/login`,
    method: 'post',
    data
  })
}

export function api_logout(data) {
  return request({
    url: `/logout`,
    method: 'post',
    data
  })
}