import request from '@/utils/request'

// 全部演出-场馆分类
export function api_theaterList(data) {
  return request({
    url: `/theater/theaterList?groupcode=${data.groupcode}`,
    method: 'get'
  })
}

// 全部演出-场馆列表
export function api_projectPage(data) {
  return request({
    url: `/project/projectPage?groupcode=${data.groupcode}&page=${data.page}&size=${data.size}&sort=${data.sort}&typename=${data.typename}&prodatetime=${data.prodatetime}&proname=${data.proname}&protype=${data.protype}&theatercode=${data.theatercode}`,
    method: 'get'
  })
}