import Vue from 'vue'
import Router from 'vue-router'
import BaseLayout from '@/views/baseLayOut/index.vue'

Vue.use(Router)

export const constantRouterMap = [
  {
    path: '/',
    component: BaseLayout,
    redirect: '/home',
    name: 'MainMenu',
    children: [
      {
        path: '/home',
        name: 'MyHome',
        component: () => import('@/views/home/index.vue'),
        meta: { title: '首页' }
      },
      {
        path: '/xiaochengxu',
        name: 'XiaoChengXu',
        component: () => import('@/views/xiaochengxu/index.vue'),
        meta: { title: '小程序' }
      },
      {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/login/index.vue'),
        meta: { title: '登录' }
      },
      {
        path: '/allShow',
        name: 'AllShow',
        component: () => import('@/views/allShow/index.vue'),
        meta: { title: '全部演出' }
      },
      {
        path: '/showDetail',
        name: 'ShowDetail',
        component: () => import('@/views/showDetail/index.vue'),
        meta: { title: '商品详情' }
      },
      {
        path: '/seat',
        name: 'ChangeSeat',
        component: () => import('@/views/changeSeat/index.vue'),
        meta: { title: '影院选座' },
        props:true
      },            
      {
        path: '/sureOrder',
        name: 'sureOrder',
        component: () => import('@/views/sureOrder/index.vue'),
        meta: { title: '确认订单' }
      },
      {
        path: '/commitOrder',
        name: 'commitOrder',
        component: () => import('@/views/sureOrder/commitOrder.vue'),
        meta: { title: '确认订单' }
      },
      {
        path: '/usercenter',
        name: 'userCenter',
        component: () => import('@/views/usercenter/index.vue'),
        meta: { title: '用户中心' },
        redirect: '/usercenter/reward',
        children: [
          {
            path: '/usercenter/reward',
            name: 'rewardCenter',
            component: () => import('@/views/usercenter/reward/index.vue'),
            meta: { title: '会员中心' }
          },
          {
            path: '/order/orderlist',
            name: 'orderList',
            component: () => import('@/views/order/orderlist.vue'),
            meta: { title: '订单列表' },
          },
          {
            path: '/order/orderinfo',
            name: 'orderInfo',
            component: () => import('@/views/order/orderinfo.vue'),
            meta: { title: '订单详情' }
          },
          {
            path: '/usercenter/address',
            name: 'addressList',
            component: () => import('@/views/usercenter/address/index.vue'),
            meta: { title: '收货地址' }
          },
          {
            path: '/usercenter/realname',
            name: 'realName',
            component: () => import('@/views/usercenter/realname/index.vue'),
            meta: { title: '实名信息' }
          },
          {
            path: '/usercenter/coupon',
            name: 'couponList',
            component: () => import('@/views/usercenter/coupon/index.vue'),
            meta: { title: '优惠券' }
          },
          {
            path: '/membercard',
            name: 'memberCard',
            component: () => import('@/views/membercard/index.vue'),
            meta: { title: '会员卡' }
          },
          {
            path: '/membercard/bindcard',
            name: 'bindCard',
            component: () => import('@/views/membercard/bindCard.vue'),
            meta: { title: '会员卡绑定' }
          },
          {
            path: '/membercard/successcard',
            name: 'successCard',
            component: () => import('@/views/membercard/successCard.vue'),
            meta: { title: '成功' }
          },
          {
            path: '/membercard/activateCard',
            name: 'activateCard',
            component: () => import('@/views/membercard/activateCard.vue'),
            meta: { title: '会员卡开卡' }
          },
          {
            path: '/membercard/investCard',
            name: 'investCard',
            component: () => import('@/views/membercard/investCard.vue'),
            meta: { title: '会员卡充值' }
          },
          {
            path: '/membercard/cardInfo',
            name: 'cardInfo',
            component: () => import('@/views/membercard/cardInfo.vue'),
            meta: { title: '会员卡详情' }
          },
        ]
      }
    ]
  },
  
]

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default new Router({
  routes: constantRouterMap
})
